.pagination-container {
	display: flex;
    list-style-type: none;
    margin-bottom: -1px;
    margin-left: -20px;

}
 .pagination-container .pagination-item {
	padding: 0 18px 1px 17px;
    height: 37px;
    text-align: center;
    margin: auto 4px;
    color: rgb(255 251 251 / 87%);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 7px;
    line-height: 1.43;
    font-size: 16px;
    font-weight: 800;
    min-width: 29px
}
 .pagination-container .pagination-item.dots:hover {
	 background-color: transparent;
	 cursor: default;
}
 .pagination-container .pagination-item:hover {
	 background-color: rgba(0, 0, 0, 0.04);
	 cursor: pointer;
}
 .pagination-container .pagination-item.selected {
	background-color: rgb(228 6 6);
}
 .pagination-container .pagination-item .arrow::before {
	 position: relative;
	/* top: 3pt;
	 Uncomment this to lower the icons as requested in comments*/
	 content: '';
	/* By using an em scale, the arrows will size with the font */
	 display: inline-block;
	 width: 0.4em;
	 height: 0.4em;
	    border-right: 0.12em solid rgb(254 251 251 / 87%);
    border-top: 0.12em solid rgb(251 251 251 / 87%);
}
 .pagination-container .pagination-item .arrow.left {
	 transform: rotate(-135deg) translate(-50%);
}
 .pagination-container .pagination-item .arrow.right {
	 transform: rotate(45deg);
}
 .pagination-container .pagination-item.disabled {
	 pointer-events: none;
}
 .pagination-container .pagination-item.disabled .arrow::before {
	 border-right: 0.12em solid rgb(254 251 251 / 87%);
	 border-top: 0.12em solid rgb(254 251 251 / 87%);
}
 .pagination-container .pagination-item.disabled:hover {
	 background-color: transparent;
	 cursor: default;
}

/* Medium Devices (Desktops, Laptops) */
@media (min-width: 960px) and (max-width: 1279px) {
	/* CSS styles for medium devices */
  }