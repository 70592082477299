
  
  .card {
    background: #fff;
    border-radius: 3px;
    display: inline-block;
     height: 389px ;
    margin: 1rem;
    position: relative;
    width: 290px;
    overflow: hidden;
  }
  
  .card .top {
    display: inline-flex;
    width: 100%;
    height: 328px;
    overflow: hidden;
    justify-content: center;
  }
  
  .card .top img {
    height: 220px;
  }
  
  .card .bottom {
    height: 80px;
    width: 100%;
  }
  
  .card .bottom p {
    text-align: left;
    height: 80px;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    padding-left: 20px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #444;
    /* font-size: 18px; */
    font-family: Roboto, sans-serif;
  }
  
  .card-1 {
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  }
  
  .card-1:hover {
    /* box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22); */
    cursor: pointer;
  }


.form-form {
    width: 50%;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    margin: 0 auto;
}
.form-form .form-form-wrap {
    max-width: 745px;
    margin: 0 auto;
    min-width: 311px;
    min-height: 100%;
    align-self: center;
    width: 100%;
    height: 100vh;
    justify-content: center; 
}
.form-form .form-container {
    align-items: center;
    display: flex;
    flex-grow: 1;
    width: 100%;
    min-height: 100%;
}
.form-form .form-container .form-content {
    display: block;
    width: 100%;
    padding: 21px;
    background: #fff;
    text-align: center;
    border-radius: 15px;
    border: 1px solid #e0e6ed;
    -webkit-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.09019607843137255), 0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.09019607843137255), 0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06);
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.09019607843137255), 0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06);
}
.form-form .form-container .form-content h1 { font-size: 32px; color: #3b3f5c; }
.form-form .form-container .form-content > p {
    /* font-size: 13px; */
    color: #888ea8;
    font-weight: 600;
    margin-bottom: 35px; }
.form-form .form-form-wrap .user-meta { margin-bottom: 35px; }
.form-form .form-form-wrap .user-meta img {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin-right: 15px;
    border: 4px solid #e0e6ed;
}
.form-form .form-form-wrap .user-meta div { align-self: center; }
.form-form .form-form-wrap .user-meta p {
    /* font-size: 31px; */
    color: #3b3f5c;
    margin-bottom: 0;
}
.form-form .form-form-wrap h1 .brand-name { color: #3b3f5c; font-weight: 600; }
.form-form .form-form-wrap p.signup-link {
    /* font-size: 14px; */
    color: #3b3f5c;
    font-weight: 700;
    margin-bottom: 15px;
    text-align: center;
    margin-top: 50px;
}
.form-form .form-form-wrap p.signup-link.register {
    /* font-size: 13px; */
    color: #888ea8;
    font-weight: 600;
    margin-bottom: 25px;
    margin-top: 0;
}
.form-form .form-form-wrap p.signup-link.recovery { margin-top: 0; }
.form-form .form-form-wrap p.signup-link a { color: #1b55e2;  }
.form-form .form-form-wrap form .field-wrapper.input {
    padding: 11px 0px 16px 0;
    border-bottom: none;
    position: relative;
}
.form-form .form-form-wrap form .field-wrapper.input:focus { border: 1px solid #000; }
.form-form .form-form-wrap form .field-wrapper.toggle-pass p {
    font-weight: 600;
    color: #3b3f5c;
    margin-bottom: 0;
}
.form-form .form-form-wrap form .field-wrapper .logged-in-user-name {
    /* font-size: 37px; */
    color: #3b3f5c;
}
.form-form .form-form-wrap form .field-wrapper label {
    /* font-size: 17px; */
    font-weight: 700;
    color: #3b3f5c;
    margin-bottom: 8px;
}
.form-form .form-form-wrap form .field-wrapper.terms_condition { margin-bottom: 20px; }
.form-form .form-form-wrap form .field-wrapper.terms_condition label {
    /* font-size: 14px; */
    color: #888ea8;
    padding-left: 31px;
    font-weight: 100;
}
.form-form .form-form-wrap form .field-wrapper.terms_condition a { color: #1b55e2; }
.form-form .form-form-wrap form .field-wrapper svg.feather-user { top: 53px; }
.form-form .form-form-wrap form .field-wrapper svg.feather-lock { top: 50px; }
.form-form .form-form-wrap form .field-wrapper svg.feather-at-sign { top: 47px; }
.form-form .form-form-wrap form .field-wrapper svg.feather-at-sign.register { top: 53px; }
.form-form .form-form-wrap form .field-wrapper svg:not(.feather-eye) {
    position: absolute;
    left: 12px;
    color: #888ea8;
    fill: rgba(0, 23, 55, 0.08);
    width: 20px;
    height: 20px;
}
.form-form .form-form-wrap form .field-wrapper svg.feather-eye {
    position: absolute;
    top: 49px;
    right: 13px;
    color: #888ea8;
    fill: rgba(0, 23, 55, 0.08);
    width: 17px;
    cursor: pointer;
}
.form-form .form-form-wrap form .field-wrapper input {
  display: inline-block;
  vertical-align: middle;
  border-radius: 6px;
  min-width: 50px;
  max-width: 700px;
  width: 100%;
  transition: all 0.2s ease-in-out 0s;
  color: #3b3f5c;
  font-weight: 600;
  /* font-size: 16px; */
  padding: 13px 38px 9px 8px;
  border: thick double #252627;
}
.avatar.avatar-xl {
  text-align: -webkit-center !important;
}
.form-form .form-form-wrap form .field-wrapper input::-webkit-input-placeholder {
    color: #d3d3d3;
    /* font-size: 14px; */
}
.form-form .form-form-wrap form .field-wrapper input::-ms-input-placeholder {
    color: #d3d3d3;
    /* font-size: 14px; */
}
.form-form .form-form-wrap form .field-wrapper input::-moz-placeholder {
    color: #d3d3d3;
    /* font-size: 14px; */
}
.form-form .form-form-wrap form .field-wrapper { width: 100% }
.form-form .form-form-wrap form .field-wrapper button.btn {
  align-self: center;
  width: 100%;
  padding: 11px 14px;
  /* font-size: 17px; */
  letter-spacing: 2px;
  background-color: #ffcc29;
  border: none;
  color: #0c0c0c;
  font-weight: 600;
}
.form-form .form-form-wrap form .field-wrapper a.forgot-pass-link {
    font-weight: 600;
    color: #888ea8;
    display: block;
    letter-spacing: 1px;
    /* font-size: 12px; */
    margin-bottom: 8px;
}
.form-form .form-form-wrap form .field-wrapper .n-chk .new-control-indicator { top: 2px; border: 1px solid #bfc9d4; background-color: #f1f2f3;}
.form-form .form-form-wrap form .field-wrapper .n-chk .new-control-indicator:after { top: 54%; }
.new-control.new-checkbox.checkbox-primary>input:checked~span.new-control-indicator { border: none; }
.form-form .form-form-wrap form .division {
    text-align: center;
    /* font-size: 13px; */
    margin: 35px 0 38px 0;
}
.form-form .form-form-wrap form .social { text-align: center; }
.form-form .form-form-wrap form .social a {
    background: transparent;
    box-shadow: none;
    border: 1px solid #e0e6ed;
    padding: 12px 10px;
    width: 181px;
}
.form-form .form-form-wrap form .social a.social-fb { margin-right: 15px; }
.form-form .form-form-wrap form .social a.social-fb svg { color: #1b55e2; }
.form-form .form-form-wrap form .social a.social-fb .brand-name { color: #1b55e2; }
@media (max-width: 991px) {
    .form-form { width: 100%; }
    .form-form .form-form-wrap { min-width: auto; margin: 0 auto; }
    .form-form .form-container .form-content { border-radius: 15px; }
}
@media (max-width: 575px) {
    .form-form .form-form-wrap form .field-wrapper.toggle-pass { margin-bottom: 28px; }
    .form-form .form-form-wrap form .social a.social-fb {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10px;
    display: block;}
    .form-form .form-form-wrap form .social a.social-github {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10px;
    display: block;}
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .form-form .form-form-wrap { width: 100%; }
    .form-form .form-container { height: 100%; }
}
@supports (-ms-ime-align: auto) {
    .form-form .form-form-wrap { height: 100%; }
}
  label {
    padding: 4px 6px;
    line-height: 190%;
    outline-style: none;
    transition: all .6s;
  }
  
  .hiddenCB div {
    display: inline;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .hiddenCB input[type="checkbox"],
  .hiddenCB input[type="radio"] {
    display: block;
    
  }
  
  .hiddenCB label {
    
    cursor: pointer;
  }
  
  .hiddenCB input[type="checkbox"]+label:hover{
    /* background: #ffcc29;
    border-style: ridge; */
  }
  
  .hiddenCB input[type="checkbox"]:checked+label {
    background: #ffcc29;
    /* border-style: ridge; */
    border: 5px solid #ffcc29;
    border-radius: 9px;
    width: 98px;
  }
  
  .hiddenCB input[type="checkbox"]:checked+label:hover{
    background: rgba(0, 128, 128, 0, .7);
  }

  .logoimg{
    width:40%;
    height:100%;

  }

  form.text-left.form {
    text-align: left !important;
}
p.help.is-danger {
  color: darkred;
}

button.button.btn.btn-primary.login-form-button.ant-btn.is-block.is-info.is-fullwidth {
  background-color: #ffcc29;
  border: none;
  
}

button.button.tagselectionbutton.btn.btn-primary.login-form-button.ant-btn.is-block.is-info.is-fullwidth {
  float: right;
  width: 110px;
  /* margin: 28px; */
  margin: 165px 5px 13px 1px;
}


button.button.tagselectionbuttonunderscreen.btn.btn-primary.login-form-button.ant-btn.is-block.is-info.is-fullwidth {
  float: right;
  width: 110px;
  /* margin: 28px; */
   margin: 67px  5px 13px 1px; 
  padding: 11px 14px;
    /* font-size: 17px; */
    letter-spacing: 2px;
    background-color: #ffcc29;
    border: none;
    color: #0c0c0c;
    font-weight: 600
}

button.button.usernamebutton.btn.btn-primary.login-form-button.ant-btn.is-block.is-info.is-fullwidth {
  /* float: right; */
    width: 110px;
    margin: 0px;
    margin-top: 12px;
 }
 .hiddenCB h3 {
  /* font-size: 21px; */
  margin-bottom: 21px;
  margin-top: 7px;
 
  font-weight: 700;
}



label.taptwolable {
  background-color: #ffcc29 !important;
  border-radius: 9px !important;
  color: #000 !important;
  width: 98px !important;
  background: #ffcc29;
  border: 5px solid #ffcc29;
  border-radius: 9px !important;
  width: 98px !important;
}

.form-container.outer {
  background-color: white;
}

.field-wrapper.input.mb-2 {
  padding: 3px 8px 21px 10px;
    float: left;
}

.le-checkbox {
  appearance: none;
  position: absolute;
  top: 15%;
  left: 9px;
  transform: translateY(-50%);
  /* background-color: #F44336; */
  width: 17px;
  height: 18px;
  border-radius: 44px;
  margin: 0px;
  outline: none;
  transition: background-color .5s;
}
.le-checkbox:before {
  /* content:'';
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%) rotate(45deg);
  background-color:#ffffff;
  width:20px;
  height:5px;
  border-radius:40px;
  transition:all .5s; */
}

.le-checkbox:after {
  /* content:'';
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%) rotate(-45deg);
  background-color:#ffffff;
  width:20px;
  height:5px;
  border-radius:40px;
  transition:all .5s; */
}
.le-checkbox:checked {
  background-color:#4CAF50;
}
.le-checkbox:checked:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translate(-4px, 3px) rotate(45deg);
  background-color: #ffffff;
  width: 7px;
  height: 2px;
  border-radius: 40px;
}

.le-checkbox:checked:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translate(3px, 2px) rotate(-45deg);
  background-color: #ffffff;
  width: 13px;
  height: 1px;
  border-radius: 40px;
}

.checkdiv {
  position: relative;
  padding: 4px 8px;
  border-radius:40px;
  margin-bottom:4px;
  min-height:30px;
  padding-left:40px;
  display: flex;
  align-items: center;
}
.checkdiv:last-child {
  margin-bottom:0px;
}
.checkdiv span {
  position: relative;
  vertical-align: middle;
  line-height: normal;
}

