@import url('https://fonts.googleapis.com/css2?family=Delicious+Handrawn&display=swap');
body{
    margin: 0;
    padding:0;
    font-family: "Open Sans", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.my-tooltip{
    background-color: rgb(217, 188, 247);
    padding: 2px 4px;
    border-radius: 4px;
}

/* Minimum and maximum height */
@media (max-height: 768px) and (min-height: 800px) {
    .scroll-container {
        /* height: 426px; */
       height: 360px;
      }
      .scroll-div {
        height: 356px;
      
      }
}

/* Minimum and maximum height */
@media (max-height: 800px) and (min-height: 1024px) {
    .scroll-container {
        /* height: 426px; */
       /* height: 370px !important; */
      }

      .scroll-div {
        height: 356px;
      
      }
}

@media (max-height: 1024px) and (min-height: 1080px) {
    .scroll-container {
        /* height: 426px; */
       /* height: 570px; */
      }

      .scroll-div {
        height: 356px;
      
      }
}


@media (max-height: 1080px) and (min-height: 1440px) {
    /* CSS rules for screens with a width of at least 1366px and a height of at least 768px */
    
      /* .scroll-container {
        height: 371px;
    } */
  }

  @media (max-height: 1440px) and (min-height: 2160px) {
    /* CSS rules for screens with a width of at least 1366px and a height of at least 768px */
    
      /* .scroll-container {
        height: 371px;
    } */
  }

 
  


