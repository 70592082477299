@import url('https://fonts.googleapis.com/css2?family=Delicious+Handrawn&display=swap');
body{
    margin: 0;
    padding:0;
    font-family: "Open Sans", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.my-tooltip{
    background-color: rgb(217, 188, 247);
    padding: 2px 4px;
    border-radius: 4px;
}


@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  /* display: flex; */
  position: relative;
  overflow: hidden !important
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.headercls {
  color: black !important;
  text-align: center;
  width: 100%;
  /* font-size: 13px; */
  background-color: #e6ebee;
  /* border-radius: 25px 25px 0 0; */
  color: #393b45;
  padding: 0px 0px 0px 0px;
  font-weight: 800;
  margin: 1px 6px 10px 7px;
  position: relative;
  box-shadow: 0 3px 10px 0 #aaa;
  cursor: pointer;

}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 1000; /* Ensure the overlay is above other content */
}
.pro-sidebar.collapsed {
  width: 80px;
  min-width: 24px !important;
}

.pro-sidebar::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #141b2d;
}

.pro-sidebar::-webkit-scrollbar
{
	width: 6px;
	background-color: #141b2d;
}

.pro-sidebar::-webkit-scrollbar-thumb
{
	background-color: #141b2d;
}

.menucls {
 text-align: center;
    width: 100%;
    background-color: #33373e;
    border-radius: 0px 0px 0 0;
    color: #e6ebee;
    padding: 0px 0;
    font-weight: 800;
    margin: 0;
    /* overflow: scroll; */
}
#sidemenucls{
 display: flex;
    flex-direction: row;
    height: auto;
    width: 242px;
    justify-content: space-between;
    align-items: center;
    margin: 2px auto;
    font-weight: 500;
    padding: 0px 8px 1px 1px;
    background-color: #4e4e4e;

}
.my-list {
  display: block;
/*  margin: 30px auto;*/
/*width: 292px ;*/
 height: 100%;
 margin-left: 13px;
}

.left-col {
   position: absolute;
    top: -30px;
    left: 5px;
    align-items: flex-start !important;

}

.right-col {
    position: absolute;
    top: -16px;
    right: 12px;
}
.upercls{
  margin-top: -63px !important;
    margin-left: -8px !important;
}
.timeCls {
  display: flex;
  margin-top: -2px;
  margin-left: 20px;
}
span.time-span {
  margin-left: -18px;
}
span.name-span {
  margin-left: 29px;
}

.scroll-div{
  overflow-y: auto !important;
}

.actionbtn {
  /* font-weight: 500 !important; */
  border: 1px solid #fff7f7 !important;
  background-color: #0f0f0f !important;
  color: #f8fcffd6 !important;
  right: -3px !important;
  /* font-size: 12px !important; */
  top: -4px;
}
.playpusebtn {
     position: fixed !important;
    /* font-weight: 500 !important; */
    border: 1px solid #fff7f7 !important;
    background-color: #4c95bd !important;
    color: #f8fcffd6 !important;
    /* right: -3px !important; */
    /* font-size: 12px !important; */
    /* top: -5px; */
    /* position: absolute !important; */
    z-index: 9999999 !important;
    bottom: -1px;
    /* left: 1px; */
    width: 66px;
    right: 22px;
    top: 871px;
  
}
.headside{
  width: 12px !important;
}
.col {
 /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    margin: 0px 1px 0px 2px;
    padding-top: 30px;
    font-weight: 700;

}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.collist {
 margin-top: 0px !important;
 padding-top: 0px;
 
}

.colsidebar{
  overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-items: revert-layer;
    cursor: pointer;
    /* font-size: 13px; */
}

.sideMenuBarCls{
  background-color: goldenrod !important;
  min-width: 100%;
}

.sideMenuBarClsActive{
  background-color: goldenrod !important;
}

.colaction {
 display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    margin: 0px 1px 0px 57px;
    padding-top: 30px;
    font-weight: 700;
}

#stats {
  display: flex;
  flex-direction: row;
  height: 78px ;
  width: 211px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  font-weight: 500;
}

.box{
  width: 100%;
  height: 100vh;
  padding: 1% 2%;
  background: #fff;
  min-height: 200px;
  max-height: 220px;
  box-sizing: border-box;
}

#statse {
  display: flex;
  flex-direction: row;
  height: 86px;
  width: auto;
  justify-content: space-between;
  align-items: center;
  margin: -4px 28px 4px 26px;
  font-weight: 500;
}

ol.my-list.style-3.underOl {
  margin-left: 26px;
}

ol.my-list.style-3.underOlm {
  margin-left: 26px;
}

.empty-state {
  width: 750px;
  margin: 40px auto;
  background: #ffffff;
  box-shadow: 1px 2px 10px #e1e3ec;
  border-radius: 4px;
}
.empty-state__content {
  padding: 48px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.empty-state__content .empty-state__icon {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  border-radius: 200px;
  justify-content: center;
  background-color: #f7fafc;
  box-shadow: 0px 2px 1px #e1e3ec;
}
.empty-state__content .empty-state__icon img {
  width: 170px;
}
.empty-state__content .empty-state__message {
  color: #38a169;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 0.85rem;
}
.empty-state__content .empty-state__help {
  color: #a2a5b9;
  font-size: 0.875rem;
}

.credit {
  color: #a2a5b9;
  font-size: 0.75rem;
  text-align: center;
}
.credit a {
  color: #444;
}

.css-ybol96-MuiButtonBase-root-MuiTab-root {
  min-width: 68px !important;
}

#headcls{
  /*display: flex;
  flex-direction: row;
  width: 225px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  font-weight: 500;
  height: 20px;*/

      display: flex;
    flex-direction: row;
    width: 181px;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    font-weight: 500;
    height: 25px;

}
.completecls{
  background-color: #81DD81;
  color: #080707;
  height: 56px;
}

.pendingcls{
  background-color: #FA7E72;
    color: #080707;
    height: 56px;
  }
.newcls{
  background-color:#FFCC29;
    color: #080707;
    height: 56px;
}
.actioncls{
  background-color:#b1b1b1;
    color: #080707;
    height: 56px;
}
.notecls{
  display: flex;
    justify-content: center
}
.continer {
    postition: "absolute";
    bottom: 0 ;
    z-index: 1000 ;
    flex-direction: "row";
    justify-content: "space-around";
    align-items: "center";
    width: "100%";
    height: 80;
    background-color: dark;
    padding-bottom: 15
  }
  .orderlist{
    position: relative;
    margin-left: 275px !important;
    
  }
  .orderlist.sidebarCollapsed {
    margin-left: 56px !important;
}

.boxm:nth-of-type(odd) {
  background-color: #ede0e0;;
  }
      
  .boxm:nth-of-type(even) {
  background-color:#fff;
  }
  

.rounded-none {
  border-radius: 0px !important;
}

/* Base styles */
body, html {
  height: 100%;
  margin: 0;
  padding: 0;
}

.shopping__item:nth-child(odd) {
/*    background-color: #c6ccc687;*/
    /* height: 3px !important;  */
    height: auto !important; 
}

.shopping__item:nth-child(even) {
    background-color: #c7c4c473;
    /* height: 3px !important; */
    height: auto !important; 
}

.shopping__itemh:nth-child(odd) {
/*    background-color: #c6ccc687;*/
     
}

.shopping__itemh:nth-child(even) {
    background-color: #c7c4c473;
    
}


.containern {
  /* display: flex; */
  min-height: 100vh;
}

.sidebar {
  width: 250px; /* Adjust width as needed */
  background-color: #f0f0f0;
  padding: 20px;
}

.main-content {
  flex: 1;
  overflow-y: auto; /* Enable vertical scroll for main content */
  margin-top: -24px !important;
  margin-bottom: 70px !important;
  max-height: calc(122vh - 9rem);
}

li.pagination-item.dots {
    display: none;
}


.footerbar {
  width: 100%;
  background-color: #1f2a40;
  color: #fff;
  text-align: center;
  padding: 20px 0;
  position: fixed;
  bottom: 0;
  margin-top: -53px;
  z-index: 9999999 !important;
}
.card-links {
  list-style: none;
  margin: 0;
  padding: 0;
  /* overflow-y: scroll; */
}

.card-links hr {
  border-bottom: 0;
  border-top: 1px solid #ddd;
  margin: 0;
}
.center-col {
  position: absolute;
  top: -19px;
  left: 200px;
  align-items: flex-start !important;
 

}

#blink_text{	
  animation-name:blink;
  animation-duration:3s;
  animation-timing-function:ease-in;
  animation-iteration-count:Infinite;
  }

  .blink_me {
    animation: blinker 1s linear infinite;
  }
  
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

@keyframes blink{
  0%{color:rgb(12, 12, 12) !important;}
  50%{color:rgb(234, 171, 11)  !important;}
  100%{color:rgb(170, 145, 21)  !important;}
}
.card-links li {
  margin: 0;
  text-align: initial;
  padding: 3px 6px;
  border-left: 1px solid #fff;
  transition: background-color 0.2s ease,
              border-left 0.2s ease,
              color 0.2s ease;
}

.underOl li {
  margin: 0;
  /* padding: 14px 6px; */
  /* padding: 22px 6px; */
  padding: 0px 0px;
  border-left: 1px solid #fff;
  transition: background-color 0.2s ease,
              border-left 0.2s ease,
              color 0.2s ease;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: clip;
}

.underOlm li {
  margin: 0;
  padding: 8px 6px;
  /* padding: 22px 6px; */
  /* padding: 0px 0px; */
  border-left: 1px solid #fff;
  transition: background-color 0.2s ease,
              border-left 0.2s ease,
              color 0.2s ease;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: clip;
}


.card-links  li:hover {
  /* border-left: 1px solid #3498db;
  background: #f6f6f6;
  color: #44474a; */
}

.shopping__item {
  display: flex;
  align-items: center;
  gap: 0.6em;
}

.shopping__itemh {
/*  display: flex;*/
  align-items: center;
  gap: 0.6em;
}

.shopping__check {
  appearance: none;
  cursor: pointer;
  
  margin-top: 1px;
  padding: 3px;
  
  aspect-ratio: 1/1;
  height: 1.2em;
  
  border: 1px solid var(--primary-color);
  border-radius: 4px;
}

.shopping__label {
  user-select: none;
  cursor: pointer;
  
/*  flex-grow: 1;*/
}

.shopping__check:checked {
  background: var(--primary-color);
  background-clip: content-box;
  
  opacity: 0.4;
}

.shopping__check:checked + .shopping__label  {
  text-decoration: line-through; /* Add line-through */
  text-decoration-color: var(--primary-color); /* Set line color */
  text-decoration-thickness: 2px; /* Set line thickness */
  
  opacity: 0.4;
}

.shopping__check:checked + .shopping__span {
  text-decoration: line-through; /* Add line-through */
  text-decoration-color: var(--primary-color); /* Set line color */
  text-decoration-thickness: 2px; /* Set line thickness */
  
  opacity: 0.4;
}
.orderList li{
  height: auto !important;
}
ol.my-list-modal.card-links.orderList {
     /* height: 385px !important;  */
     height: auto;
     
}
.cancelledClss{
  text-align: center !important;
}
.newClss{
  text-align: center !important;
}
.titleClss{
  text-align: left !important;
}
.completedClss{
  text-align: center !important;
}
.cancelledCls{
  border-radius: 18%;
  background-color: #C06C84;
  color: white !important;
  height: 38px;
}
.newCls{
  border-radius: 18%;
  background-color: #7ECFC0;
  color: white !important;
  height: 38px;
}
.titleCls{
  color: #e50831;
  border-radius: 18%;
  /* background-color: #8B4F80; */
  /* color: white !important; */
  height: 38px;
}
.completedCls{
  border-radius: 18%;
  background-color: #9EA1D4;
  color: white !important;
  height: 38px;
}
.priority{
   background-color: #ff0000 !important;
  color: #fff !important;
  font-weight: 600 !important;
  /* font-size: 15px !important; */
}
.delayed{
   background-color: #0d0d0c !important;
  color: #fff !important;
  font-weight: 600 !important;
  /* font-size: 15px !important; */
}
.bumb{
  background-color:  #934610 !important;
  color: #fff !important;
    font-weight: 600 !important;
    /* font-size: 15px !important; */

}
.cooking{
  background-color: #9611e9 !important;
  color: #fff !important;
    font-weight: 600 !important;
    /* font-size: 15px !important; */

}
.join{
   background-color: #357ae8 !important;
   color: #fff !important;
    font-weight: 600 !important;
    /* font-size: 15px !important; */
}
.print{
     background-color:#1810f9 !important;
     color: #fff !important;
    font-weight: 600 !important;
    /* font-size: 15px !important; */
}
.info{
   background-color:#4542a5 !important;
   color: #fff !important;
    font-weight: 600 !important;
    /* font-size: 15px !important; */
}
.recall{
    background-color:#0096fb !important;
    color: #fff !important;
    font-weight: 600 !important;
    /* font-size: 15px !important; */
}
.recall-last{
  background-color:#173234c7 !important;
  color: #fff !important;
    font-weight: 600 !important;
    /* font-size: 15px !important; */

}

.pause{
  max-width: 1px !important;
    background-color: #1408c9 !important;
  color: #fff !important;
    font-weight: 600 !important;
    /* font-size: 15px !important; */

}
.css-havevq-MuiSvgIcon-root {
    /* font-size: 34px !important; */
  }

span.shopping__span{
  /* float:right !important; */
  font-weight: 600;
  color:red;
}

.card-links::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255);
  background-color: #f6f6f7;;
}

.card-links::-webkit-scrollbar
{
  width: 6px;
  background-color: #f6f6f7;
}

.card-links::-webkit-scrollbar-thumb
{
  background-color: #f6f6f7;
}

/*
 *  STYLE 3
 */

.style-3::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #333846;;
}

.style-3::-webkit-scrollbar
{
  width: 6px;
  background-color: #333846;;
}

.style-3::-webkit-scrollbar-thumb
{
  background-color: #333846;;
}

.scroll-button {
  position: absolute;
 /* top: 0;
  width: 100%;
  padding: 10px;*/
  background-color:  rgb(0 0 0 / 0%);;
  color: white;
  border: none;
  cursor: pointer;
}

.scroll-button.up {
  top: 0;
  right: -1px;
  color: black;
}

.scroll-button.down {
  bottom: 0;
  right: -1px;
  color: black;
}
.scroll-button.readmore{
  bottom: 0;
  left: -3px;
  color: black;
}
.scroll-container {
  position: relative;
  height: 234px;
  overflow: hidden;
}
.scroll-div {
  position: relative;
  height: 234px;
  overflow: hidden;
}


.css-havevq-MuiSvgIcon-root {
  height: 19px !important;
}
.my-list-modal{
    display: block;
    margin: 0px auto !important;
    width: 333px;
    height: 100%;
}

.leftbtn{
  float: right;
}

.rightbtn{
  float: left;
}

svg{
  color: #080707;
}

button svg {
  color: whitesmoke;
}

.readmore
svg {
  color: #080707;
}

button.scroll-button.readmore {
  left: 2px;
  bottom: 5px;
}

.fontcls {
  float: left;
  text-align: center;
  /* margin-left: 72px; */
  position: relative;
  left: 92px;
  top: 15px;
}

header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionStatic.css-qlz4ei {
  background-color: #ffffff !important;
  color: #ffffff !important;
}



@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
.animate-flicker {
   -webkit-animation: flickerAnimation .5s infinite;
   -moz-animation: flickerAnimation .5s infinite;
   -o-animation: flickerAnimation .5s infinite;
    animation: flickerAnimation .5s infinite;
}
.modalcls{
  background-color: #f9f9f9 !important;
  color: black !important;
  border: none !important;
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #ffffff;
}
.login-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  max-width: 1000px;
  background-color: white;
  box-shadow: 0 0 40px rgba(0,0,0,0.16);
  overflow: hidden;
  margin: 0 auto;
  border-radius: 12px;
}

#login-form {
  flex: 1 0 100%;
  max-width: 480px;
  width: 100%;
  padding: 60px;
}
#login-form p {
  margin-bottom: 30px;
  color: red;
}
#login-form p.form-title {
  color: #333333;
  font-family: 'Josefin Sans', sans-serif;
  /* font-size: 42px; */
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0;
}

#login-form .ant-form-item-label > label.ant-form-item-required::before {
  display: none;
}
.ant-form-item-control-input-content {
  text-align: left;
}
#login-form .ant-input-affix-wrapper {
  padding: 12px 15px;
}
#login-form_username {
  height: 48px;
}
label.label {
    color: black;
}
.field {
    margin-top: 18px;
}
#login-form{
  height: 42px;
  letter-spacing: 1px;
  border-radius: 6px;
}
.login-form-button {
  width: 100%;
 

}
.illustration-wrapper {
  display: flex;
  align-items: flex-end;
  max-width: 800px;
  min-height: 100%;
  background-color: #fffdf2;
}
.illustration-wrapper img {
  display: block;
  width: 100%;
}
#leftCol {
  position: fixed;
  width: 150px;
  background-color: #1f2a40 !important;
  /* font-size: 21px; */
  overflow-y: scroll;
  top: 0;
  bottom: 0;
  /* //height: 94vh !important; */
  overflow-y: auto;
    /* margin-top: -24px !important; */
    /* margin-bottom: 70px !important; */
    max-height: calc(122vh - 9rem);
    /* box-shadow: 0 3px 10px 0 #aaa; */
}

#leftsidbar{
  position: fixed;
  width: 59px;
  background-color: #1f2a40 !important;
  /* font-size: 21px; */
  overflow-y: hidden;
  top: 0;
  bottom: 0;
  height: 94vh !important;
}
.centered-div {
  position: fixed;
  top: 3%;
  left: 50%;
  transform: translate(6%, -50%);
  color: red;
  background-color: #141b2d;
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
  z-index: 11;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-43deg);
}
.labelCls{
  width: 98px;
  background: #ffcc29;
  border-style: ridge;
  border: 5px solid #ffcc29;
  border-radius: 9px;
  width: 98px;
}

.linkCls{
  color: #20232a;;
  text-decoration: none;
  /* font-size: 12px; */
}

.css-1k0i2s9-MuiPaper-root-MuiAppBar-root{
  background-color: #ffffff  !important;
  color:#ffffff  !important;
}

.form-container.outer.tabone{
  /* background-color: #20232a; */
  background-color: #33373e;
    position: absolute;
    left: 206px;
    min-width: 66%;
    top: -58px;
}

.css-1grhalw-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #9ca9cb;
  font-weight: 900;
  background-color: #cccccc;
}
button.MuiButtonBase-root {
  border-radius: 0px;
  background-color: #ccc;
  font-weight: 900;
  background-color: #ffcc29;
  border: 5px solid #434446 !important
}
.bottombar button.MuiButtonBase-root {
  border-radius: 0px;
  background-color: #ccc;
  font-weight: 900;
  background-color: #ffcc29;
  border: 0px solid #434446 !important
  
}

.linkCls .tabcls {
  width: 222px;
}
.hide{display: none !important;}

.headercls  button.MuiButtonBase-root {

  border: 0px solid #434446 !important
  
}
.modalcls button.MuiButtonBase-root {

  /* border: 0px solid #434446 !important */
  
}
.backbutton{
  background: #ebe41e !important;
    background-image: -webkit-linear-gradient(top, #ebe41e, #aba448) !important;
    background-image: -moz-linear-gradient(top, #ebe41e, #aba448) !important;
    background-image: -ms-linear-gradient(top, #ebe41e, #aba448) ;
    background-image: -o-linear-gradient(top, #ebe41e, #aba448) !important;
    background-image: linear-gradient(to bottom, #ebe41e, #aba448) !important;
    -webkit-border-radius: 28 !important;
    -moz-border-radius: 28 !important;
    border-radius: 28px !important;
    font-family: Georgia !important;
    color: #030303 !important;
    /* font-size: 15px !important; */
    padding: 10px 20px 10px 20px !important;
    text-decoration: none !important;
}
.Mui-selected {
  color: #1f2a40 !important;
  background-color:  rgb(237 238 241) !important;
}
@media (min-width: 768px) {
  /* Adjust the height for larger screens */
  #leftCol {
    height: calc(100vh - 60px); /* Example: Deducting 60px for a fixed header */
  }
}
#content {
  position: relative;            
  margin-left: 150px;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
    height: auto !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    z-index: 101 !important;
}
li.shopping__item.modifire {
  height: 1px !important;
}
li.shopping__itemh.modelitem {
  height: 33px !important;
}
li.shopping__itemh.hasitemcls {
  height: auto !important;
}
.hover\:bg-light-gray:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / 0%) !important;
}
.e-control, .e-control [class^=e-], .e-control [class*=" e-"] {
  box-sizing: border-box;
  background-color: #6b1ce9;
}
@media screen and (max-width: 1023px) {
  .login-box {
    flex-direction: column;
    box-shadow: none;
  }
  .illustration-wrapper {
    max-width: 100%;
    min-height: auto;
  }
  #login-form {
    max-width: 100%;
  }
}

/* Media Query Css */



/* 1024x600 */
@media (min-width: 1024px) and (min-height: 600px) {
  .scroll-container {
    height: 161px;
  }
}

/* 1024x768 */
@media (min-width: 1024px) and (min-height: 768px)  {
  /* CSS rules for screens with a width of at least 1024px and a height of at least 768px */
  .scroll-container {
    height: 242px;
  }
  .headercls{
    /* width: 10rem ; */
    width: auto ;
  }
  button.button.tagselectionbutton.btn.btn-primary.login-form-button.ant-btn.is-block.is-info.is-fullwidth {
    float: right;
    width: 110px;
    /* margin: 28px; */
    margin: 80px 5px 13px 1px !important;
}
}

@media only screen and (min-width: 1024px) and (orientation: landscape)
{ /* Your Styles... */ }



/* 1280x800 */
@media (min-width: 1280px) and (min-height: 800px) {
  /* CSS rules for screens with a width of at least 1280px and a height of at least 800px */
  .scroll-container {
    height: 384px
  
}
.headercls {
width: auto ;
}

.scroll-div {
  height: 356px;

}

}
      


@media (min-width: 2048px) and (min-height: 1152px) {
  /* CSS rules for screens with a width of at least 1366px and a height of at least 768px */
  .headercls {
    width: 24rem;
    }
    .scroll-container {
      height: 371px;
  }
}


@media (min-width: 2560px) and (min-height: 1440px) {
  /* CSS rules for screens with a width of at least 1366px and a height of at least 768px */
  .headercls {
    width: 24rem !important;
    }
    .scroll-container {
      height: 371px;
  }
}


/* Define the media query for 1366x768 */
@media (min-width: 1366px) and (max-height: 768px) {
  /* CSS rules for screens with a width of at least 1366px and a height of at least 768px */
  .scroll-container {
    height: 271px !important;
  }
  .headercls {
    /* width: 24rem !important; */
    width: 28rem !important;
    }

    .scroll-div {
      height: 356px;
    }
}

/* Define a more specific media query for 1366x768 */
@media (min-width: 1366px) and (min-width: 1366px) and (max-width: 1366px) and (min-height: 768px) and (max-height: 768px) {
  /* CSS rules for screens with a width and height exactly 1366x768 */
  .scroll-container {
    height: 241px !important; /* Adjust the height as needed */
  }
  .headercls {
    width: 15rem !important;
    }
}

/* 1366x 1024 */
@media (min-width: 1366px) and (min-height: 1024px) {
  /* CSS rules for screens with a width of at least 1366px and a height of at least 1024px */
  .scroll-container {
    height: 378px !important;
  }
  .headercls {
    /* width: 15rem ; */
    width: auto ;
    
    }

    .scroll-div {
   
      height: 366px ;
     
  }
}

/* 1440x900 */
@media (min-width: 1440px) and (min-height: 900px) {
  /* CSS rules for screens with a width of at least 1440px and a height of at least 900px */
  .scroll-container {
    height: 334px
  
}
}

/* 1600x900 */
@media (min-width: 1600px) and (min-height: 900px) {
  /* CSS rules for screens with a width of at least 1600px and a height of at least 900px */
  .scroll-container {
    height: 334px
  
}
}

/* 1680x1050 */
@media (min-width: 1680px) and (min-height: 1050px) {
  /* CSS rules for screens with a width of at least 1680px and a height of at least 1050px */
  .scroll-container {
    height: 410px;
}
}

/* 1920x1080 */
@media (min-width: 1920px) and (min-height: 1080px) {
  .scroll-container {
    height: 401px;
  }
  .headercls{
    width: 24rem;
  }
}

/* 1920x1200 */
@media (min-width: 1920px) and (min-height: 1200px) {
  /* CSS rules for screens with a width of at least 1920px and a height of at least 1200px */
  .scroll-container {
    height: 484px;
  }
}



@media (min-width: 2048px) and (min-height: 1152px) {
  .scroll-container {
    height: 384px;
  }
}

/* Media query for 640x480 */
@media (min-width: 640px) and (min-height: 480px) {
  /* Your CSS rules for this screen size */
  /* .scroll-container {
    height: 20px;
  } */
}


/* Media query for 1280x720 */
@media (min-width: 1280px) and (min-height: 720px) {
  /* Your CSS rules for this screen size */
  .scroll-container {
   /* // height: 248px; */
   height: 223px;
  }

  .scroll-div {
    /* // height: 248px; */
    /* height: 223px; */
    height: 380px;
   }
}

/* Media query for 1280x720 */
@media (min-width: 1280px) and (min-height: 800px) {
  /* Your CSS rules for this screen size */
  .scroll-container {
    height: 241px ;
    /* height: 394px; */
    /* height: 360px; */
  }
}

/* Media query for 1920x1080 */
@media (min-width: 1920px) and (min-height: 1080px) {
  /* Your CSS rules for this screen size */
  .scroll-container {
    height: 401px;
  }
  .headercls{
    width: 24rem ;
  }
}

/* Media query for 1920x1080 */
@media (min-width: 1920px) and (min-height: 1080px) {
  /* Your CSS rules for this screen size */
  .scroll-container {
    height: 401px;
  }
  .headercls{
    /* width: 24rem  ; */
    width: auto ; 
  }

  .scroll-div {
    /* height: 401px ; */
    height: 454px ;
  
  }
}

/* Media query for 1920x1200 */
@media (min-width: 1920px) and (min-height: 1200px) {
  /* Your CSS rules for this screen size */
  .scroll-container {
    height: 489px;
  }
}

/* Media query for 2160x1440 */
@media (min-width: 2160px) and (max-height: 1440px) {
  /* Your CSS rules for this screen size */
  .scroll-container {
    height: 489px;
  }
}

/* Media query for 2048x1080 */
@media (min-width: 2048px) and (min-height: 1080px) {
  /* Your CSS rules for this screen size */
  .scroll-container {
    /* height: 426px; */
    height: 395px;
  }
}
@media (min-width: 2560px) and (min-height: 1440px) {
  /* Your CSS rules for this screen size */
  .scroll-container {
    /* height: 426px; */
   height: 570px;
  }
  .headercls{
    width: 31rem !important;
  }
}



/* Media query for 2160x1620 */
@media (min-width: 2160px) and (min-height: 1620px) {
  /* Your CSS rules for this screen size */
  .scroll-container {
    /* height: 426px; */
    height: 695px;
  }
}
/* Media query for 3840x2160 */
@media (min-width: 3840px) and (min-height: 2160px) {
  /* Your CSS rules for this screen size */
  .scroll-container {
    height: 937px;
  }
}

/* Media query for 7680x4320 */
@media (min-width: 7680px) and (min-height: 4320px) {
  /* Your CSS rules for this screen size */
  .scroll-container {
    height: 960px;
  }
}


.dark .dark\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity));
    float: left;
}






