@import url('https://cdn.syncfusion.com/ej2/material.css');

#preview{
    background: transparent
    url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png')
    no-repeat;
    display: inline-block;
    height:80px;
    margin: 10px 0;
    min-width:300px;
    max-width: 600px;
    background-color: #008000;
}

.swal2-square {
    border-radius: 0 !important;  /* Remove rounded corners */
    width: 410px !important;
    aspect-ratio: 1 !important;  /* Makes it perfectly square */
  }
  
  .swal2-confirm-large {
    font-size: 1.25rem !important;
    padding: 0.75rem 2rem !important;
    min-width: 120px !important;
    border-radius: 0 !important;  /* Square button */
  }
  .bkcolor{
    background-color: #ece7b1;
  }